import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styles from '../../styles/Footer.module.css';

export default function NotAvailablePage() {
  const { t } = useTranslation();
  return (
    <div
      style={{ margin: '100px', textAlign: 'center', marginBottom: '400px' }}
    >
      <h3>{t('notFoundPage')}.</h3>
      <div>
        <Link href='/'>
          <a className={styles.terms}>{t('homeMenu')}</a>
        </Link>
      </div>
    </div>
  );
}
